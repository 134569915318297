import type { Job } from 'bullmq'
import { useEffect, useState } from 'react'

import { $path } from '~/utils/route-helpers'

export const useJob = (jobToPull?: { id: string; queue: string }) => {
  const [job, setJob] = useState<Job>()

  useEffect(() => {
    if (!jobToPull) return

    const es = new EventSource($path('/jobs/:queueName/:jobId', { queueName: jobToPull.queue, jobId: jobToPull.id }))

    es.addEventListener('message', (ev: MessageEvent<string>) => {
      const job = JSON.parse(ev.data)
      setJob(job)

      if (job.finishedOn) {
        es.close()
      }
    })

    return () => {
      es.close()
    }
  }, [jobToPull])

  return job
}
