import { useRevalidator } from '@remix-run/react'
import { cva, cx } from 'class-variance-authority'
import { useCallback, useEffect, useRef } from 'react'
import type { Toast } from 'react-hot-toast'
import { toast } from 'react-hot-toast'

import { useJob } from '~/components/jobs'
import { ProgressBar } from '~/components/ProgressBar'
import type { IconVariant } from '~/components/ui-components/Icon'
import Icon from '~/components/ui-components/Icon'
import type { FlashMessage } from '~/services/session'
import { useClickOutside } from '~/utils/hooks/use-click-outside'

const colors = cva('', {
  variants: {
    type: {
      info: 'bg-info-50 text-info-800',
      error: 'bg-error-50 text-error-800',
      success: 'bg-success-25 text-success-800',
      warning: 'bg-warning-25 text-warning-800'
    }
  },
  defaultVariants: {
    type: 'info'
  }
})

const buttonColors = cva('', {
  variants: {
    type: {
      info: 'hover:bg-info-100',
      error: 'hover:bg-error-100',
      success: 'hover:bg-success-100',
      warning: 'hover:bg-warning-100'
    }
  },
  defaultVariants: {
    type: 'info'
  }
})

const defaultIcons: Record<FlashMessage['type'], IconVariant> = {
  error: 'Error',
  success: 'Success',
  warning: 'Info',
  info: 'Info',
  job: 'Info'
}

export const ToastNotification = ({ t, message }: { t: Toast; message: Partial<FlashMessage> }) => {
  const closeButtonRef = useRef<HTMLButtonElement>(null)
  const previousFocusElement = useRef<HTMLElement | null>(null)

  // Effect that sets focus on the notification when it appears, and resets focus to previous element when it disappears
  useEffect(() => {
    // If-statement is required because React executes the effect twice during development.
    if (!previousFocusElement.current) {
      previousFocusElement.current = document.activeElement as HTMLElement
    }

    closeButtonRef.current?.focus()
  }, [])

  const onDismiss = useCallback(() => {
    previousFocusElement.current?.focus?.()
    toast.dismiss(t.id)
  }, [t.id])

  const conditionallyDismiss = useCallback(() => {
    if (message.type !== 'job') {
      onDismiss()
    }
  }, [onDismiss])

  const wrapperRef = useRef<HTMLDivElement>(null)
  useClickOutside(wrapperRef, conditionallyDismiss)

  return (
    <div
      ref={wrapperRef}
      className={cx(
        t.visible ? 'animate-enter' : 'animate-leave',
        'pointer-events-auto flex w-full max-w-lg flex-col rounded p-4 shadow-xl',
        colors({ type: message.type === 'job' ? 'info' : message.type })
      )}
      data-test-id='notification'
    >
      <div
        className={cx(t.visible ? 'animate-enter' : 'animate-leave', 'pointer-events-auto flex gap-4', {
          'items-center': !message.footer,
          'items-start': message.footer
        })}
      >
        <Icon variant={message.icon || defaultIcons[message.type || 'info']} className='h-6 w-6' />
        <div className='w-0 flex-1'>
          <div>{typeof message.message === 'string' ? message.message : message.message?.(onDismiss)}</div>
          {message.job && <NotificationJobProgress onDismiss={onDismiss} message={message} />}
        </div>
        <div className='flex'>
          <button
            ref={closeButtonRef}
            onClick={onDismiss}
            className={cx(
              `flex h-6 w-6 shrink-0 items-center justify-center rounded-full`,
              buttonColors({ type: message.type === 'job' ? 'info' : message.type })
            )}
          >
            <Icon variant='Close' className='h-4 w-4' />
          </button>
        </div>
      </div>
      {message.footer?.(onDismiss)}
    </div>
  )
}

export const ToastNotificationFooter: React.FC<{ children: JSX.Element }> = (props) => {
  return <div className='ml-7 mr-2 mt-2'>{props.children}</div>
}

const NotificationJobProgress = ({ onDismiss, message }: { onDismiss: () => void; message: Partial<FlashMessage> }) => {
  let { revalidate } = useRevalidator()

  const job = useJob(message.job)
  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (job && (job.progress === 100 || job.finishedOn)) {
      timeout = setTimeout(() => {
        onDismiss()
        revalidate()
      }, 1000)
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [job, revalidate, onDismiss])

  return <div className='mt-2'>{typeof job?.progress === 'number' ? <ProgressBar value={job.progress} /> : JSON.stringify(job)}</div>
}
